import React, { useState } from "react"
import styled from "@emotion/styled"
import { connect, useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import { navigate } from "gatsby"
import { MdEmail } from "react-icons/md"
import { FaMapMarkerAlt } from "react-icons/fa"
import { css } from "@emotion/core"

import wechat from "../../static/wechat_code_lancy.png"
import linkedin from "../../static/linked-in-c.png"
import {
  Layout,
  Title,
  PrimaryButton,
  Input,
  Textarea,
  SEO,
} from "../components"
import { sendContactEmail } from "../redux/actions"
import dropALine from "../../images/fmessage-sent.svg"
import { mq } from "../utils/media-query"
import { getCurrentPath } from "../redux/actions/pathAction"

const Contact = ({ location, getCurrentPath }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const validate = () => {
    for (const type of Object.keys(data)) {
      if (!data[type]) {
        return false
      } else if (
        type === "email" &&
        !data[type].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return false
      }
    }
    return true
  }

  const handleSubmit = e => {
    console.log("submit triggered")
    console.log(data)
    e.preventDefault()
    dispatch(sendContactEmail(data))
    navigate("/contact/success")
  }

  return (
    <Layout>
      <SEO title="Contact Us" />
      <StyledSection>
        <Title
          title={intl.formatMessage({ id: "contact.title" })}
          fontColor="var(--clr-primary-300)"
        />
        <article className="text-wrap">
          <p className="contact-text">
            {intl.formatMessage({ id: "contact.subtitle" })}
          </p>
        </article>
        <div className="section-center">
          <div className="image-wrap">
            <img src={dropALine} alt="drop-a-line" />
          </div>

          <article className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <Input
                  type="text"
                  name="name"
                  placeholder={intl.formatMessage({ id: "contact.name" })}
                  value={data.name}
                  onChange={e =>
                    setData(Object.assign({}, data, { name: e.target.value }))
                  }
                />
                <Input
                  type="email"
                  placeholder={intl.formatMessage({ id: "contact.email" })}
                  name="email"
                  value={data.email}
                  onChange={e =>
                    setData(Object.assign({}, data, { email: e.target.value }))
                  }
                />
                <Textarea
                  name="message"
                  rows="5"
                  placeholder={intl.formatMessage({ id: "contact.message" })}
                  value={data.message}
                  onChange={e => {
                    setData(
                      Object.assign({}, data, { message: e.target.value })
                    )
                  }}
                ></Textarea>
              </div>
              <PrimaryButton
                type="submit"
                className="submit-btn"
                disabled={!validate()}
              >
                {intl.formatMessage({ id: "btn.send" })}
              </PrimaryButton>
            </form>
          </article>
          <div className="info-area">
            <div className="item-container">
              <MdEmail
                css={css`
                  font-size: 2rem;
                  color: var(--clr-primary-200);
                  cursor: pointer;
                  margin-top: -0.1rem;
                  margin-right: 0.25rem;
                `}
              />
              <p>info@iwaretravel.ca</p>
            </div>
            <div className="item-container">
              <FaMapMarkerAlt
                css={css`
                  font-size: 1.8rem;
                  color: var(--clr-primary-200);
                  cursor: pointer;
                  margin-top: -0.1rem;
                  margin-right: 0.25rem;
                `}
              />
              <p>Suite 230, 3553 31 St. NW Calgary, AB T2L2K7, Canada</p>
            </div>
            <div className="item-container">
              <img src={wechat} alt="wechat_code" width="100px" />
              <img
                src={linkedin}
                alt="linkedIn-link"
                width="100px"
                css={css`
                  cursor: pointer;
                `}
                onClick={() =>
                  typeof window !== undefined &&
                  window.open(
                    "https://www.linkedin.com/company/iware-travel/",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
      </StyledSection>
    </Layout>
  )
}

const StyledSection = styled.section`
  padding: 5rem 0;

  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    ${mq[1]} {
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      align-items: center;
    }
  }

  .contact-form {
    background: var(--clr-neutral-100);
    border-radius: var(--border-radius-md);
    text-align: center;
    box-shadow: var(--solid-shadow-primary300);
    transition: var(--transition);
    width: 90%;
    max-width: 35rem;
    border: 2px solid rgba(9, 31, 82, 1);
    margin: 0 auto;
  }

  .form-group {
    padding: 1.5rem 1.5rem;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    margin-bottom: 1.25rem;
    border: 2px solid var(--clr-primary-300);
    border-radius: var(--border-radius);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
    font-family: var(--ff-tertiary);
  }
  .form-control::placeholder {
    color: var(--clr-neutral-300);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
  }

  .submit-btn {
    display: block;
    padding: 1rem 2rem;
    margin: 0 auto 2rem;
    text-transform: capitalize;
  }

  .text-wrap {
    text-align: center;
    font-family: var(--ff-quaternary);
    font-size: 1rem;
    margin-bottom: 3rem;
    color: var(--clr-primary-300);
  }

  .image-wrap {
    display: none;

    ${mq[1]} {
      display: block;
      width: 90%;
      justify-self: center;
    }
  }

  .item-container {
    display: flex;
  }

  .item-container img {
    margin-right: 50px;
  }

  .info-area {
    grid-column: span 1/-1;
    justify-self: center;
  }
`

export default connect(null, { getCurrentPath })(Contact)
